#flowsViz {
    position: absolute;
    top: 0;
    left: 0;
}

path.dashboard-card-flow {
    stroke-width: 5;
    fill: none;

    .chakra-ui-dark & {
        stroke: var(--chakra-colors-gray-800);
    }
    .chakra-ui-light & {
        stroke: var(--chakra-colors-gray-200);
    }

    &[flow="a-to-b"],
    &[flow="b-to-a"] {
        stroke: var(--chakra-colors-enposol-500);
        stroke-dasharray: 10, 4;
    }

    &[flow="null"] {
        stroke-width: 2;
    }
}
