.simple-keyboard.enposol-keyboard {
  background-color: #0006;
  border-radius: 10px 10px 0 0;
}

.simple-keyboard.enposol-keyboard .hg-button {
  height: 45px;
  border-radius: 8px;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0009;
  color: white;
  font-weight: bold;
}

.simple-keyboard.enposol-keyboard .hg-button:active {
  background: #fff9;
  box-shadow: 0 1px #fffb;
  color: black;
}

#root .simple-keyboard.enposol-keyboard + .simple-keyboard-preview {
  background: #1c4995;
}
