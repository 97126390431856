.markdown-guide {
  ul {
    margin-bottom: var(--chakra-space-4);
    li {
      margin-left: var(--chakra-space-6);

      strong {
        padding-left: var(--chakra-space-2);
        padding-right: var(--chakra-space-2);
        .chakra-ui-light & {
          background: var(--chakra-colors-enposol-50);
        }
        .chakra-ui-dark & {
          background: var(--chakra-colors-enposol-950);
        }
      }
      em {
        .chakra-ui-light & {
          color: var(--chakra-colors-enposol-700);
        }
        .chakra-ui-dark & {
          color: var(--chakra-colors-enposol-100);
        }
      }
    }
  }
}
