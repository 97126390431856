
// Disable the energy-inefficient CPU-heavy continuous fade-in-out animation
// of greyed out skeleton structures to not tax user devices more than
// what is reasonable
.chakra-skeleton {
  animation: none !important;
}

// Little bit of keeping the UI together they way we need to
html,
body {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
body,
#root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}