$hexagon-width: 250px;
$hexagon-height: $hexagon-width * 0.55;
$hexagon-margin: 25px;

$hexagon-title-color: var(--chakra-colors-enposol-500);
$hexagon-title-color--hover--dark: var(--chakra-colors-gray-950);
$hexagon-title-color--hover--light: var(--chakra-colors-white);
$hexagon-border-color: var(--chakra-colors-enposol-500);
$hexagon-border-color--hover: var(--chakra-colors-enposol-500);
$hexagon-image-color--dark: var(--chakra-colors-gray-950);
$hexagon-image-color--light: var(--chakra-colors-white);

$honeycomb-max-width: 1200px;
$viewport-padding: 32px; // equivalent of var(--chakra-space-8)
$viewport-threshold: $hexagon-width + $hexagon-margin + $viewport-padding;

$half-hexagon-margin: calc($hexagon-margin / 2);
$half-hexagon-width: calc($hexagon-width / 2);
$half-hexagon-height: calc($hexagon-height / 2);
$quarter-hexagon-height: calc($half-hexagon-height / 2);

.honeycomb {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  justify-content: center;
  align-items: center;
  max-width: $honeycomb-max-width;
  margin: 0 auto;
  padding: 0;
  transform: translateY($quarter-hexagon-height);

  &-cell {
    flex: 0 1 $hexagon-width;
    max-width: $hexagon-width;
    height: $hexagon-height;
    margin: 0.95 * $half-hexagon-height $half-hexagon-margin $hexagon-margin;
    position: relative;
    padding: 0.5em;
    cursor: pointer;

    text-align: center;
    z-index: 1;

    &__title {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      hyphens: auto;
      word-break: break-word;
      text-transform: uppercase;
      color: $hexagon-title-color;
      font-weight: 700;
      font-size: 1.75em;
      transition: opacity, color 350ms;

      > small {
        font-weight: 300;
        margin-top: 0.25em;
      }
    }

    &__image {
      object-fit: cover;
      object-position: center;
    }

    &::before,
    &::after {
      content: "";
    }

    &::before,
    &::after,
    &__image {
      top: -50%;
      left: 0;
      width: 100%;
      height: 200%;
      display: block;
      position: absolute;
      clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
      z-index: -1;
    }

    &::before {
      background: $hexagon-border-color;
      transform: scale(1.055);
    }

    &::after {
      opacity: 1;
      transition: opacity 350ms;

      .chakra-ui-dark & {
        background: $hexagon-image-color--dark;
      }
      .chakra-ui-light & {
        background: $hexagon-image-color--light;
      }
    }

    &:hover {
      .honeycomb-cell__title {
        .chakra-ui-dark & {
          color: $hexagon-title-color--hover--dark;
        }
        .chakra-ui-light & {
          color: $hexagon-title-color--hover--light;
        }
      }

      &::before {
        background: $hexagon-border-color--hover;
      }

      &::after {
        opacity: 0;
      }
    }
  }

  &__placeholder {
    display: none;
    opacity: 0;
    width: $hexagon-width;
    margin: 0 $half-hexagon-margin;
  }
}

@media (max-width:#{2 * $viewport-threshold}) {
  .honeycomb-cell {
    margin: #{$half-hexagon-margin + $half-hexagon-height} $hexagon-margin;
  }
}

@media (min-width: #{2 * $viewport-threshold + 1}) and (max-width: #{3 * $viewport-threshold}) {
  .honeycomb-cell:nth-child(3n) {
    margin-right: calc(50% - $half-hexagon-width);
    margin-left: calc(50% - $half-hexagon-width);
  }

  .honeycomb__placeholder:nth-child(3n + 5) {
    display: block;
  }
}

@media (min-width: #{3 * $viewport-threshold + 1}) and (max-width: #{4 * $viewport-threshold}) {
  .honeycomb-cell:nth-child(5n + 4) {
    margin-left: calc(50% - #{$hexagon-width + $hexagon-margin});
  }

  .honeycomb-cell:nth-child(5n + 5) {
    margin-right: calc(50% - #{$hexagon-width + $hexagon-margin});
  }

  .honeycomb__placeholder:nth-child(5n),
  .honeycomb__placeholder:nth-child(5n + 3) {
    display: block;
  }
}

@media (min-width: #{4 * $viewport-threshold + 1}) {
  .honeycomb-cell:nth-child(7n + 5) {
    margin-left: calc(50% - #{$hexagon-width * 1.5 + $hexagon-margin});
  }

  .honeycomb-cell:nth-child(7n + 7),
  .honeycomb-cell:nth-child(7n + 5):nth-last-child(2) {
    margin-right: calc(50% - #{$hexagon-width * 1.5 + $hexagon-margin});
  }

  .honeycomb__placeholder:nth-child(7n + 7),
  .honeycomb__placeholder:nth-child(7n + 9),
  .honeycomb__placeholder:nth-child(7n + 11) {
    display: block;
  }
}
